<template>
  <component :is="component" v-bind="componentProps" @click="onClick">
    <slot :is-external="isExternal" :icon="icon" />
  </component>
</template>

<script lang="ts" setup>
import { useRouter, useRoute, computed } from '#imports'
import type { NuxtSvgSpriteSymbol } from '#nuxt-svg-sprite/runtime'
import { isExternalUrl } from '~/helpers/url'

const props = defineProps<{
  to?: string
  target?: string
  isLink?: boolean
  activeClass?: string
}>()

const router = useRouter()
const route = useRoute()

const href = computed(() => {
  if (!props.to) {
    return ''
  }

  if (props.to.startsWith('www')) {
    return `https://${props.to}`
  }

  return props.to
})

const component = computed(() => (href.value ? 'a' : 'div'))

const isMediaFileCanonical = computed(() => {
  if (!href.value) {
    return false
  }

  const route = router.resolve(href.value)

  if (route.name) {
    return route.name.toString().startsWith('media-canonical')
  }

  return false
})

const isExternal = computed(() => isExternalUrl(href.value))

const isActive = computed(() =>
  route.path && href.value ? route.path.startsWith(href.value) : false,
)

const componentProps = computed<Record<string, string | string[]>>(() => {
  const componentProps: Record<string, any> = {
    class: [],
  }
  if (href.value) {
    componentProps.href = href.value

    if (isExternal.value || isMediaFileCanonical.value) {
      componentProps.target = '_blank'
    } else if (props.target) {
      componentProps.target = props.target
    }
  }

  if (props.isLink) {
    componentProps.class.push('link')
    componentProps.class.push(isExternal.value ? 'is-external' : 'is-internal')
  }

  if (isActive.value) {
    componentProps.class.push(props.activeClass || 'nuxt-link-exact-active')
  }

  return componentProps
})

const icon = computed<NuxtSvgSpriteSymbol>(() => {
  if (isMediaFileCanonical.value || href.value.includes('.pdf')) {
    return 'attachment'
  } else if (href.value.includes('https://')) {
    return 'page'
  }
  return 'info-empty'
})

const isWebform = computed(() => href.value.includes('/form/'))

function onClick(e: Event) {
  if (isExternal.value || isMediaFileCanonical.value || isWebform.value) {
    return
  }

  e.preventDefault()
  router.push(href.value)
}
</script>
